import 'react-toastify/dist/ReactToastify.css'
import '../styles/index.css'
import '../styles/globals.scss'

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import { Analytics } from '@vercel/analytics/react'
import { Amplify, Auth } from 'aws-amplify'
import mixpanel from 'mixpanel-browser'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { Dispatch, useEffect, useLayoutEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
import { useDispatch } from 'react-redux'
import { CookieConsent } from 'src/components/CookieConsent/CookieConsent'
import * as gtag from 'src/components/global/gtag'
import { ThemeOnly } from 'src/components/global/ThemeOnly'
import {
  AMPLIFY_REGION,
  AMPLIFY_USER_POOL_APP_CLIENT_ID,
  AMPLIFY_USER_POOL_ID,
  ENVIRONMENT,
  MIXPANEL_ENABLED,
  MIXPANEL_PROJECT_TOKEN,
  THEME,
  USE_GOOGLE_ANALYTICS,
} from 'src/config/config'
import { setAuthToLink } from 'src/hooks/useApolloClient'
import { removeAuth, setAuth, setProfile } from 'src/store/actions/auth'
import { wrapper } from 'src/store/setup'
import { ComponentWrapper } from 'src/templates/ComponentWrapper'

import { getUserInfo, refreshTokenApi } from './api/sso/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean
}

if (typeof document === 'undefined') {
  React.useLayoutEffect = React.useEffect
}

const MyApp = (props: AppProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { Component, pageProps }: { Component: NextApplicationPage; pageProps: any } = props
  const [mounted, setMounted] = useState(false)
  const router = useRouter()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()
  const [token, setToken] = useState<string | undefined>(undefined)
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined)

  if (THEME !== 'ilta') {
    const configure = {
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      Auth: {
        region: AMPLIFY_REGION,
        userPoolId: AMPLIFY_USER_POOL_ID,
        userPoolWebClientId: AMPLIFY_USER_POOL_APP_CLIENT_ID,
      },
    }

    Amplify.configure(configure)
    Auth.configure(configure)
  }

  const getUserByToken = async () => {
    const user = await getUserInfo()
    dispatch(
      setProfile({
        username: user?.username,
        email: user?.email,
        isSSOUser: true,
      }),
    )
  }

  const refreshToken = async (callback?: () => void) => {
    const _isSSO = localStorage.getItem('sso') === 'true'
    if (_isSSO) {
      const res = await refreshTokenApi()
      if (res) {
        setAuthToLink(res.id_token)
        setToken(res.id_token)
        localStorage.setItem('idToken', res.id_token)
        localStorage.setItem('accessToken', res.access_token)
        setUserEmail(res.email)
        dispatch(
          setAuth({
            idToken: res.id_token,
          }),
        )

        await getUserByToken()
      }
    } else {
      try {
        const res = await Auth.currentAuthenticatedUser()
        const currentSession = res.signInUserSession
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.refreshSession(currentSession.refreshToken, (err: any, session: any) => {
          if (session) {
            setAuthToLink(session.idToken.jwtToken)

            localStorage.setItem(
              `CognitoIdentityServiceProvider.${AMPLIFY_USER_POOL_APP_CLIENT_ID}.${res.username}.idToken`,
              session.idToken.jwtToken,
            )
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${AMPLIFY_USER_POOL_APP_CLIENT_ID}.${res.username}.accessToken`,
              session.accessToken.jwtToken,
            )
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${AMPLIFY_USER_POOL_APP_CLIENT_ID}.${res.username}.refreshToken`,
              session.refreshToken.token,
            )

            setUserEmail(res.attributes?.email)
            setToken(session.idToken.jwtToken)

            dispatch(
              setAuth({
                username: res.username,
                email: res.attributes?.email,
                idToken: session.idToken.jwtToken,
              }),
            )
          }
        })
      } catch (err) {
        Auth.signOut()
        dispatch(removeAuth())
      }
    }

    if (callback) {
      callback()
    }
  }

  const _setToken = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser()
      setUserEmail(res.attributes?.email)
      dispatch(
        setAuth({
          username: res.username,
          email: res.attributes?.email,
        }),
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  useLayoutEffect(() => {
    const _isSSO = localStorage.getItem('sso') === 'true'
    if (THEME !== 'ilta') {
      if (_isSSO) {
        const _token = localStorage.getItem('idToken')
        if (_token && _token !== undefined && _token !== 'undefined') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _token_decode = decodeToken(_token as string) as any

          if (!_token_decode.exp) {
            localStorage.clear()
            return
          }

          if (_token_decode.exp < Date.now() / 1000 || _token_decode.exp < Date.now() / 1000 - 300) {
            refreshToken(getUserByToken)
          } else {
            setAuthToLink(localStorage.getItem('idToken')!)
            dispatch(
              setAuth({
                idToken: localStorage.getItem('idToken') as string,
              }),
            )
            setToken(localStorage.getItem('idToken')!)
            getUserByToken()
          }
        } else {
          localStorage.clear()
        }
      } else {
        Auth.currentAuthenticatedUser().then((res) => {
          const exp = res.signInUserSession.idToken.payload.exp
          if (exp < Date.now() / 1000 || exp < Date.now() / 1000 - 300) {
            refreshToken(_setToken)
          } else {
            setAuthToLink(res.signInUserSession.idToken.jwtToken)
            dispatch(
              setAuth({
                idToken: res.signInUserSession.idToken.jwtToken,
              }),
            )
            setToken(res.signInUserSession.idToken.jwtToken)
          }
        })
      }
    }
  }, [])

  useLayoutEffect(() => {
    const _isSSO = localStorage.getItem('sso') === 'true'
    router.events?.on('routeChangeStart', async () => {
      if (_isSSO) {
        const _token = localStorage.getItem('idToken')
        if (_token && _token !== undefined && _token !== 'undefined') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _token_decode = decodeToken(localStorage.getItem('idToken') as string) as any

          if (!_token_decode.exp) {
            localStorage.clear()
            return
          }

          if (_token_decode.exp < Date.now() / 1000 || _token_decode.exp < Date.now() / 1000 - 300) {
            await refreshToken()
          } else {
            setAuthToLink(_token!)
            setToken(_token!)
          }
        } else {
          localStorage.clear()
        }
      } else {
        const res = await Auth.currentAuthenticatedUser()
        const exp = res.signInUserSession.idToken.payload.exp
        if (exp < Date.now() / 1000 || exp < Date.now() / 1000 - 300) {
          await refreshToken()
        } else {
          setAuthToLink(res.signInUserSession.idToken.jwtToken)
          setToken(res.signInUserSession.idToken.jwtToken)
        }
      }
    })

    const handleRouteChange = (url: string) => {
      if (
        url === '/signin/' &&
        router.asPath !== '/forgot/' &&
        router.asPath !== '/reset/' &&
        router.asPath !== '/signin/' &&
        router.asPath !== '/registration/' &&
        router.asPath !== '/signout/' &&
        router.asPath !== '/' &&
        /\/sso\/callback\//.test(router.asPath) === false &&
        /\/signup\/confirm\//.test(router.asPath) === false &&
        /\/signin\/sso\//.test(router.asPath) === false &&
        /\/signin\/sso\/\/start\//.test(router.asPath) === false &&
        /\/premium\/invitation\//.test(router.asPath) === false
      ) {
        if (router.asPath.includes('edit-listing')) {
          router.push(`/vendor-signin/?redirect=${encodeURIComponent(router.asPath)}`)
        } else {
          router.push(`/signin/?redirect=${encodeURIComponent(router.asPath)}`)
        }
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (USE_GOOGLE_ANALYTICS) {
      const handleRouteChange = (url: URL) => {
        gtag.pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  if (!mounted) {
    if (MIXPANEL_ENABLED && MIXPANEL_PROJECT_TOKEN) {
      mixpanel.init(MIXPANEL_PROJECT_TOKEN)
      mixpanel.track('visit_site', {
        email: userEmail || '',
      })
    }
  }

  useEffect(() => {
    if (MIXPANEL_ENABLED) {
      mixpanel.set_group('environment', ENVIRONMENT || 'develop')
      setMounted(true)
    }
  }, [])

  return (
    <>
      <div className={`${THEME}`}>
        <HubspotProvider>
          <ComponentWrapper token={token}>
            <Component {...pageProps} />
          </ComponentWrapper>
        </HubspotProvider>
        <ThemeOnly theme="legaltech">
          <CookieConsent />
        </ThemeOnly>
        <Script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/21422664.js"></Script>
        <Script type="text/javascript" src="//js.hsforms.net/forms/shell.js"></Script>
        {typeof window !== 'undefined' && <Analytics />}
      </div>
    </>
  )
}

export default wrapper.withRedux(MyApp)
